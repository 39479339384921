<template>
  <div class="home">
    <Loading class="load" v-if="$store.state.loadNum < 1"></Loading>
    <Right></Right>
    <Cnt ref="cnt"></Cnt>
    <Page1 ref="page1"></Page1>
    <Page2 ref="page2"></Page2>
    <Page3 ref="page3"></Page3>
    <Page4 ref="page4"></Page4>
    <!-- <Page5></Page5> -->
  </div>
</template>

<script>
import Right from "@/components/Pc/right.vue";
import Cnt from "@/components/Pc/cnt.vue";
import Page1 from "@/components/Pc/page1.vue";
import Page2 from "@/components/Pc/page2.vue";
import Page3 from "@/components/Pc/page3.vue";
import Page4 from "@/components/Pc/page4.vue";
import Page5 from "@/components/Pc/page5.vue";
import Loading from "@/components/Pc/load.vue";
export default {
  name: "Home",
  components: {
    Right: Right,
    Cnt: Cnt,
    Page1: Page1,
    Page2: Page2,
    Page3: Page3,
    Page4: Page4,
    Page5: Page5,
    Loading: Loading,
  },
  data() {
    return {};
  },
  created() {
    // this.axios({
    //   method: "get",
    //   url: "/devices",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    // }).then((res) => {
    //   console.log("", res.data);
    // });
    console.log();
  },
  methods: {
    roll() {
      let newPage = 0;
      // newPage = Math.round(this.$store.state.scrollY / (window.innerHeight * 1.5));
      if (
        this.$store.state.scrollY >
        this.$refs.page2.$el.offsetTop - window.innerHeight / 2
      ) {
        newPage = 1;
      }
      if (
        this.$store.state.scrollY >
        this.$refs.page3.$el.offsetTop - window.innerHeight / 2
      ) {
        newPage = 2;
      }
      if (
        this.$store.state.scrollY >
        this.$refs.page4.$el.offsetTop - window.innerHeight / 2
      ) {
        newPage = 3;
      }
      // if (this.$store.state.currentPage < 2) {
      //   newPage = Math.round(this.$store.state.scrollY / (window.innerHeight * 1.5));
      // } else {
      //   newPage =
      //     Math.round(
      //       (this.$store.state.scrollY - window.innerHeight * 1.5) / window.innerHeight
      //     ) + 1;
      // }

      if (newPage != this.$store.state.currentPage) {
        // if (newPage >= 2) {
        //   this.$store.state.currentPage = 2;
        // } else {
        //   this.$store.state.currentPage = newPage;
        // }
        this.$store.state.currentPage = newPage;
        console.log(this.$store.state.currentPage);
      }

      // if (this.$store.state.scrollY == 0) {
      //   this.$refs.cnt.$el.style.top = this.$store.state.scrollY + "px";
      //   this.$refs.cnt.$el.style.width = "100%";
      //   this.$refs.cnt.$el.style.height = window.innerHeight + "px";
      // } else if (this.$store.state.scrollY < window.innerHeight) {
      //   this.$refs.cnt.$el.style.top = this.$store.state.scrollY + 150 + "px";
      //   this.$refs.cnt.$el.style.width =
      //     (1 - this.$store.state.scrollY / window.innerHeight) * 100 + "%";
      //   this.$refs.cnt.$el.style.height = window.innerHeight - 150 + "px";
      // }else if(this.$store.state.scrollY >= window.innerHeight){
      //   this.$refs.cnt.$el.style.top = window.innerHeight + 150 + "px";
      // }
      if (this.$store.state.scrollY == 0) {
        this.$refs.cnt.$el.style.position = "fixed";
        this.$refs.cnt.$el.style.top = "auto";
        this.$refs.cnt.$el.style.width = "100%";
        this.$refs.cnt.$el.style.height = window.innerHeight + "px";
      } else if (this.$store.state.scrollY < this.$refs.page2.$el.offsetTop) {
        this.$refs.cnt.$el.style.position = "fixed";
        this.$refs.cnt.$el.style.top = "auto";
        this.$refs.cnt.$el.style.bottom = 0;
        this.$refs.cnt.$el.style.width =
          (1 -
            this.$store.state.scrollY /
              (this.$refs.page2.$el.offsetTop * 0.8)) *
            100 +
          "%";
        this.$refs.cnt.$el.style.height =
          (1 -
            this.$store.state.scrollY / (this.$refs.page2.$el.offsetTop * 3)) *
            100 +
          "vh";
        // this.$refs.cnt.style.width =
        //   (1 - this.$store.state.scrollY / window.innerHeight) * 100 + "%";
        // this.$refs.cnt.style.height = (1 - this.$store.state.scrollY / window.innerHeight) * 100 + "%";
      } else if (this.$store.state.scrollY >= this.$refs.page2.$el.offsetTop) {
        this.$refs.cnt.$el.style.position = "absolute";
        this.$refs.cnt.$el.style.top =
          this.$refs.page2.$el.offsetTop + 150 + "px";
      }
    },
  },
  mounted() {
    this.$store.state.page1 = this.$refs.page1.$el;
    this.$store.state.page2 = this.$refs.page2.$el;
    this.$store.state.page3 = this.$refs.page3.$el;
    this.$store.state.page4 = this.$refs.page4.$el;
    // 监听滚动事件
    this.$store.state.bar.addEventListener("scroll", () => {
      this.roll();
    });
  },
  beforeDestroy() {},
  watch: {
    // "$route.path": {
    //   immediate: true,
    //   handler(newVal, oldVal) {
    //     if (newVal === "/Home" || newVal === "/") {
    //       this.$store.state.currentPage = 0;
    //       window.scrollTo({
    //         top: 0,
    //       });
    //       console.log(this.$store.state.currentPage, "aaa");
    //     }
    //   },
    // },
  },
};
</script>

<style scoped>
.home {
  min-height: 100vh;
  /* background: #cbe0e0; */
  /* background: #181a23; */
  background: #f2f4f4;
  position: relative;
}
.wp {
  position: absolute;
  width: 100%;
  height: 100vh;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.load {
  width: 100%;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 200;
}
</style>
<style>
.home .el-progress__text {
  font-size: 32px !important;
  color: #025169;
}
.home .el-progress-circle__path {
  stroke: #025169;
}
.home .el-progress-circle__track {
  stroke: rgba(11, 11, 11, 0.1) !important;
}
.vb > .vb-dragger {
  z-index: 999;
}
</style>
