import * as THREE from "three";
////////////创建相机 角度,看的宽高,摄像机视角近段,摄像机视角远端
const camera = new THREE.PerspectiveCamera(
  45,
  window.innerWidth / window.innerHeight,
  1,
  10000
);
//设置相机位置 x,y,z
// camera.position.set(0, 1000, 1500);
// camera.position.set(500, 800, 500);
camera.position.set(1000, 700, 700);
export default camera;