<template>
  <!-- <div :class="$store.state.currentPage==1
          ? 'page2 animate__animated animate__fadeIn'
          : 'page2 animate__animated animate__fadeOut'"> -->
  <div class="page2">
    <div class="left">
      <div class="top" @click="$router.push('/Use')">
        <img src="@/assets/img/page2-1.png" alt="" width="100%" />
        <div class="text">
          <p>应用案例</p>
          <el-button>查看详情</el-button>
        </div>
      </div>
      <div class="btm" @click="skip(list[0])">
        <img :src="list[0].cover" alt="" width="100%" height="100%" />
        <div class="text">
          <p>{{ list[0].title }}</p>
          <el-button>查看详情</el-button>
        </div>
      </div>
    </div>
    <div class="right">
      <div class="top" @click="skip(list[1])">
        <img :src="list[1].cover" alt="" width="100%" height="100%" />
        <div class="text">
          <p>{{ list[1].title }}</p>
          <el-button>查看更多</el-button>
        </div>
      </div>
      <div class="center" @click="skip(list[2])">
        <img :src="list[2].cover" alt="" width="100%" />
        <div class="text">
          <p>{{ list[2].title }}</p>
          <el-button>查看更多</el-button>
        </div>
      </div>
      <div class="btm" @click="skip(list[3])">
        <img :src="list[3].cover" alt="" width="100%" />
        <div class="text">
          <p>{{ list[3].title }}</p>
          <el-button>查看更多</el-button>
        </div>
        <!-- <div class="btm-left">
          <img src="@/assets/img/page2-2.png" alt="" width="100%" />
          <div class="text">
            <p>智能源路灯</p>
            <el-button>查看更多</el-button>
          </div>
        </div>
        <div class="btm-right">
          <img src="@/assets/img/page2-2.png" alt="" width="100%" />
          <div class="text">
            <p>智能源路灯</p>
            <el-button>查看更多</el-button>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",

  data() {
    return {
      list: [{}, {}, {}, {}, {}],
    };
  },
  methods: {
    skip(item) {
      this.$router.push({
        path: "/Use/info",
        query: { title: item.title, cnt: item.content, imgUrl: item.cover },
      });
    },
  },
  created() {
    this.axios({
      method: "get",
      url: "/wzn/application-case/page",
      params: {
        pageNo: 1,
        pageSize: 5,
        // title: "home",
      },
    }).then((res) => {
      console.log("应用案例", res.data);
      this.list = res.data.data.list;
    });
  },
};
</script>

<style scoped>
.page2 {
  height: 100vh;
  position: relative;
  z-index: 50;
  /* display: flex;
  flex-direction: column;
  justify-content: end; */
}
.left,
.right {
  width: 20%;
  position: absolute;
  top: 150px;
  /* background: red; */
  height: calc(100% - 150px);
  display: flex;
  flex-direction: column;
}
.left {
  left: 4%;
}
.right {
  right: 4%;
}
.left .top,
.left .btm,
.right .top,
.right .center,
.right .btm-left,
.right .btm-right,
.right .btm {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
.left .top {
  margin-bottom: 6%;
  flex: 2;
}
.left .btm {
  flex: 1;
}

.left .top img,
.left .btm img,
.right .top img,
.right .center img,
.right .btm-left img,
.right .btm-right img,
.right .btm img {
  transition: all 0.5s;
}
.left .top .text,
.left .btm .text,
.right .top .text,
.right .center .text,
.right .btm-left .text,
.right .btm-right .text,
.right .btm .text {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.left .top .text p,
.left .btm .text p,
.right .top .text p,
.right .center .text p,
.right .btm-left .text p,
.right .btm-right .text p,
.right .btm .text p {
  color: #fff;
  font-size: 1.5vw;
  margin-bottom: 20px;
}
.left .top .text p,
.right .top .text p {
  /* color: #000; */
}
.left .top::before,
.left .btm::before,
.right .top::before,
.right .center::before,
.right .btm-left::before,
.right .btm-right::before,
.right .btm::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  pointer-events: none;
}
.left .top::after,
.left .btm::after,
.right .top::after,
.right .center::after,
.right .btm-left::after,
.right .btm-right::after,
.right .btm::after {
  width: 100%;
  height: 70%;
  position: absolute;
  left: 0%;
  bottom: 0%;
  content: "";
  background: linear-gradient(-180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
  opacity: 0;
  transition: all 0.5s;
}
.left .top:hover::before,
.left .btm:hover::before,
.right .top:hover::before,
.right .center:hover::before,
.right .btm-left:hover::before,
.right .btm-right:hover::before,
.right .btm:hover::before {
  animation: gradient-rectangle 1s 1;
  animation-delay: 0s;
  animation-timing-function: cubic-bezier(0, 0, 0.18, 0.96);
}
.left .top:hover::after,
.left .btm:hover::after,
.right .top:hover::after,
.right .center:hover::after,
.right .btm-left:hover::after,
.right .btm-right:hover::after,
.right .btm:hover::after {
  opacity: 1;
}
@keyframes gradient-rectangle {
  0% {
    transform: scaleY(0);
    transform-origin: bottom;
    background: linear-gradient(
      to top,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.6)
    );
    opacity: 1;
  }
  100% {
    transform: scaleY(1);
    transform-origin: top;
    background: linear-gradient(
      to top,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.6)
    );
    opacity: 0;
  }
}
.left .top:hover img,
.left .btm:hover img,
.right .top:hover img,
.right .center:hover img,
.right .btm-left:hover img,
.right .btm-right:hover img,
.right .btm:hover img {
  transform: scale(1.1);
}
.right .top,
.right .center,
.right .btm {
  flex: 1;
}
.right .center {
  margin: 6% 0;
}
.right .btm {
  display: flex;
}
/* .right .btm > div {
  flex: 1;
} */
/* .right .btm > .btm-left {
  margin-right: 6%;
} */
</style>
<style>
.page2 .el-button {
  background: #00c8a2;
  color: #fff;
  width: 120px;
  height: 40px;
  margin-bottom: 0px;
  opacity: 0;
  transition: all 0.5s;
}
.left .top:hover .el-button,
.left .btm:hover .el-button,
.right .top:hover .el-button,
.right .center:hover .el-button,
.right .btm-left:hover .el-button,
.right .btm-right:hover .el-button,
.right .btm:hover .el-button {
  transform: scale(1.1);
  opacity: 1;
  margin-bottom: 30px;
}
</style>
