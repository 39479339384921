<template>
  <div class="page1">
    <!-- <Cnt></Cnt> -->
    <div
      :class="
        $store.state.currentPage == 0
          ? 'title animate__animated animate__zoomIn'
          : 'title animate__animated animate__zoomOut'
      "
    >
      <h2 style="margin-bottom: 20px">微智能数字孪生</h2>
      <img src="@/assets/img/down.png" alt="" width="80" @click="down" />
    </div>
  </div>
</template>

<script>
// import Cnt from "./cnt.vue";
export default {
  name: "",
  // components: {
  //   Cnt: Cnt,
  // },
  data() {
    return {};
  },
  methods: {
    down() {
      window.scrollTo({
        top: this.$store.state.page2.offsetTop,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style scoped>
.page1 {
  position: relative;
  z-index: 50;
  height: 150vh;
}
.title {
  position: absolute;
  width: 100%;
  top: 45%;
  text-align: center;
  color: #fff;
  overflow: hidden;
}
.title h2 {
  font-size: 72px;
  letter-spacing: 1em;
}
.title img {
  cursor: pointer;
}
</style>
