<template>
  <div class="page5"></div>
</template>

<script>
// import Swiper from "./swiper.vue";
export default {
  name: "",
  // components: {
  //   Swiper: Swiper,
  // },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
.page5 {
  /* height: 70vh; */
  position: relative;
  z-index: 50;
  box-sizing: border-box;
}
</style>
