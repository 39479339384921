<template>
  <div ref="cnt" id="cnt" class="cnt" v-show="$store.state.loadNum >= 1">
    <div
      v-if="$store.state.currentPage == 1"
      :class="
        $store.state.currentPage == 1
          ? 'wp animate__animated animate__fadeIn'
          : 'wp animate__animated animate__fadeOut'
      "
    >
      <p style="margin-bottom: 20px;color:#fff">
        结合3D可视化打造实景页面,实现对路灯的控制交互等
      </p>
      <!-- <a href="http://222.88.186.81:23878/">http://222.88.186.81:23878</a> -->
    </div>
  </div>
</template>
<script>
import * as THREE from "three";
import gsap from "gsap";
import scene from "@/three/scene";
import camera from "@/three/camera";
import axesHelper from "@/three/axesHelper";
import renderer from "@/three/renderer";
import controls from "@/three/controls";
import raycaster from "@/three/raycaster";
import Map from "@/three/setModel/map";
import Car from "@/three/setModel/car";
import StreetLamp from "@/three/setModel/streetLamp";
import StreetLampList from "@/three/setModel/streetLampList";
import Light from "@/three/setModel/light";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import * as dat from "dat.gui";
export default {
  name: "",
  data() {
    return { cntBol: false, clock: null, mouse: { x: 0, y: 0 } };
  },
  methods: {
    init() {
      scene.add(camera);
      // scene.add(axesHelper);
      document.getElementById("cnt").appendChild(renderer.domElement);
      Map();
      Car();
      StreetLampList.forEach((item) => {
        StreetLamp(
          item.name,
          item.id,
          item.positionX,
          item.positionY,
          item.positionZ
        );
        // Light(item.id, item.positionX, item.positionY, item.positionZ);
      });
      // const loading = this.$loading({
      //   lock: true,
      //   text: "加载中",
      //   spinner: "el-icon-loading",
      //   background: "rgba(0, 0, 0, 0.7)",
      // });
      this.clock = new THREE.Clock();
      this.render();
      this.resize();
    },
    // 不断渲染
    render() {
      // controls.target.y = -(window.scrollY / window.innerHeight) * 1000;
      // camera.position.y = 700 - (window.scrollY / window.innerHeight) * 1000;
      // let time=this.clock.getElapsedTime()
      let deltaTime = this.clock.getDelta();
      controls.target.x += (this.mouse.x * 200 - controls.target.x) * deltaTime;
      // camera.position.x += (this.mouse.x * 100 - camera.position.x) * deltaTime;
      // controls.target.z += (this.mouse.x * 200 - controls.target.z) * deltaTime;
      // camera.position.y += (this.mouse.y * 100 - camera.position.y) * deltaTime;
      this.$store.state.loadNum = scene.children.length / 36;

      controls.update();
      renderer.render(scene, camera);
      // 不断渲染
      requestAnimationFrame(this.render);
    },
    // 画面变化
    resize() {
      // 监听画面变化,更新渲染画面
      window.addEventListener("resize", () => {
        // console.log(scene.children);
        // console.log("画面变化了");
        // 更新摄像头
        camera.aspect = window.innerWidth / window.innerHeight;
        // 更新摄像机投影矩阵
        camera.updateProjectionMatrix();
        // 更新渲染器
        renderer.setSize(window.innerWidth, window.innerHeight);
        // 设置渲染器像素比
        renderer.setPixelRatio(window.devicePixelRatio);
      });
      // 监听鼠标移动事件
      window.addEventListener("mousemove", () => {
        this.mouse.x = event.clientX / window.innerWidth - 0.5;
        this.mouse.y = -(event.clientY / window.innerHeight - 0.5);
      });
    },
  },
  mounted() {
    this.init();
  },
  // beforeDestroy() {
  //   this.render = null;
  // },
};
</script>

<style scoped>
.cnt {
  position: fixed;
  /* top: 0; */
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
  width: 100%;
  height: 100vh;
  outline: none;
  z-index: 10;
  overflow: hidden;
  /* transition: width 0.5s cubic-bezier(0.215, 0.61, 0.355, 1),
    height 0.5s cubic-bezier(0.215, 0.61, 0.355, 1); */
  transition: all 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  min-width: 50%;
  min-height: calc(100vh - 150px);
  max-height: 100vh;
  max-width: 100%;
}

.wp {
  position: absolute;
  z-index: 100;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* overflow: hidden; */
  font-size: 32px;
}
.wp a {
  display: block;
  color: #fff;
  cursor: pointer;
}
</style>

