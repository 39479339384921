<template>
  <div class="page3">
    <div style="width: 100%; height: 100%; position: relative">
      <div class="content" v-for="(item, index) in imgList" :key="index">
        <div class="text" v-if="index == dot">
          <span>{{ "0" + (index + 1) }}</span>
          <h2>{{ item.title }}</h2>
          <p v-html="item.content"></p>
          <div class="info" @click="info(item.skip)">了解详情</div>
        </div>
        <div class="btn" v-if="index == dot">
          <div
            :class="Index == dot ? 'btn-wp in' : 'btn-wp'"
            v-for="(Item, Index) in imgList"
            :key="Index"
            @click="dot = Index"
          >
            <span>{{ Item.title }}</span>
            <img :src="Item.cover" alt="" />
          </div>
        </div>
        <div :class="index == dot ? 'wp active' : 'wp'">
          <img :src="item.cover" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>
    
<script>
export default {
  name: "",
  data() {
    return {
      imgList: [],
      dot: 0,
    };
  },
  methods: {
    info(index) {
      if (index == 1) {
        this.$router.push("/Product");
      } else if (index == 2) {
        this.$router.push("/Use");
      } else if (index == 4) {
        this.$router.push("/Solve");
      }
    },
  },
  created() {
    this.axios({
      method: "get",
      url: "/wzn/home/page",
      params: {
        pageNo: 1,
        pageSize: 100,
        // title: "home",
      },
    }).then((res) => {
      console.log("主页", res.data);
      this.imgList = res.data.data.list;
      // this.imgList = res.data.data.list.reverse();
    });
  },

};
</script>
    
<style scoped>
.page3 {
  height: 100vh;
  margin-top: 50px;
  padding-top: 100px;
}
.content {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}
.wp {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  transition: opacity 0.3s ease;
  opacity: 0;
  animation: aa 20s linear infinite;
}
.active {
  opacity: 1;
  transition: opacity 0.3s ease;
}
.text {
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 10%;
  transform: translateY(-50%);
  color: #fff;
  width: 40%;
}
.text h2 {
  font-size: 28px;
}
.text p {
  font-size: 16px;
  line-height: 2;
  margin: 20px 0 30px;
}
.text span {
  font-size: 70px;
  color: rgba(255, 255, 255, 0.1);
  position: absolute;
  top: -60px;
  left: 0;
}
.text .info {
  font-size: 16px;
  padding-top: 10px;
  cursor: pointer;
  position: relative;
  width: 100px;
}
.text .info::before {
  content: "";
  width: 100%;
  height: 2px;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #fff;
  transform-origin: left center;
  transition: transform 0.6s ease;
}
.text .info:hover::before {
  transform: scaleX(0);
}
.btn {
  position: absolute;
  top: 50%;
  right: 10%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 10;
}
.btn-wp {
  width: 130px;
  height: 80px;
  margin: 8px 0;
  padding: 8px;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
}
.btn-wp.in {
  background-color: #2a7cca;
  border-color: #2a7cca;
}
.btn-wp:hover > span {
  opacity: 1;
}
.btn-wp > img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.btn-wp > span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.3);
  color: #fff;
  font-size: 18px;
  opacity: 0;
  transition: all 0.3s ease;
}
.wp > img {
  width: 100%;
  /* height: 100%; */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@keyframes aa {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
</style>