<template>
  <div class="page4">
    <div
      :class="
        page4TitleTop <= 0
          ? 'title-public animate__animated animate__fadeIn '
          : 'title-public animate__animated animate__fadeOut'
      "
      ref="page4Title"
    >
      <p>News and trends</p>
      <h3>
        <span class="line-l"></span>
        <span class="center">新闻动态</span>
        <span class="line-r"></span>
      </h3>
    </div>
    <div
      :class="
        page4CntTop <= 0
          ? 'news-wp animate__animated animate__fadeIn '
          : 'news-wp animate__animated animate__fadeOut'
      "
      ref="page4Cnt"
    >
      <div class="left">
        <div v-for="(item, index) in newsList" :key="index" @click="skip(item)">
          <div class="img-wp">
            <img :src="item.cover" alt="" />
          </div>
          <div class="text">
            <h2>{{ item.title }}</h2>
            <p class="time">{{ item.createTime }}</p>
            <p class="cnt" v-html="item.content"></p>
          </div>
        </div>
        <div class="more" @click="$router.push('News')">
          查看更多<span style="color: #7ea1a6">最新资讯</span>
          <img src="@/assets/img/news-right.png" alt="" width="18" />
        </div>
      </div>
      <div class="right" @click="skip(topNews)">
        <div class="img-wp">
          <img :src="topNews.cover" alt="" />
        </div>
        <div class="text">
          <p>{{ topNews.createTime }}</p>
          <h2>{{ topNews.title }}</h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import timeGet from "@/utils/timeGet.js";
export default {
  name: "",
  // components: {
  //   Swiper: Swiper,
  // },
  data() {
    return {
      topNews: "",
      newsList: [],
      page4TitleTop: "",
      page4CntTop: "",
    };
  },
  methods: {
    skip(item) {
      this.$router.push({
        path: "/News/info",
        query: { title: item.title, cnt: item.content, imgUrl: item.cover,time:item.createTime },
      });
    },
  },
  created() {
    this.axios({
      method: "get",
      url: "/wzn/articles/page",
      params: {
        pageNo: 1,
        pageSize: 100,
        isPinned: true,
      },
    }).then((res) => {
      console.log("置顶新闻", res.data);
      this.topNews = res.data.data.list[0];
      this.topNews.createTime = timeGet(this.topNews.createTime);
    });
    this.axios({
      method: "get",
      url: "/wzn/articles/page",
      params: {
        pageNo: 1,
        pageSize: 3,
        isPinned: false,
      },
    }).then((res) => {
      console.log("其他新闻", res.data);
      this.newsList = res.data.data.list.reverse().map((item) => {
        item.createTime = timeGet(item.createTime);
        return item;
      });
    });
  },
  mounted() {
    this.$store.state.bar.addEventListener("scroll", () => {
      this.page4TitleTop =
        this.$refs.page4Title.getBoundingClientRect().y -
        window.innerHeight +
        this.$refs.page4Title.clientHeight / 2;
      this.page4CntTop =
        this.$refs.page4Cnt.getBoundingClientRect().y -
        window.innerHeight +
        this.$refs.page4Cnt.clientHeight / 2;
    });
  },
};
</script>

<style scoped>
.page4 {
  /* height: 100vh; */
  /* position: relative; */
  z-index: 50;
  padding: 100px 12% 0;
  box-sizing: border-box;
}
.news-wp {
  display: flex;
}
.right {
  position: relative;
  flex: 1;
  margin-left: 80px;
  cursor: pointer;
}
.right:hover .text {
  opacity: 0.6;
}
.right:hover .img-wp img {
  transform: scale(1.1);
}
.right .text {
  position: absolute;
  color: #fff;
  bottom: 20px;
  left: 20px;
  transition: all 0.4s;
}
.right .text h2 {
  font-size: 30px;
}
.right .img-wp {
  width: 100%;
  overflow: hidden;
}
.right .img-wp img {
  width: 100%;
  height: 100%;
  transition: all 0.4s;
}
.left {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.left > div {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.left > div:hover .text {
  opacity: 0.6;
}
.left > div:hover .img-wp img {
  transform: scale(1.1);
}
.left > div > .img-wp {
  width: 180px;
  height: 120px;
  margin-right: 20px;
  overflow: hidden;
}
.left > div > .text {
  flex: 1;
  transition: all 0.4s;
}
.left > div > .img-wp img {
  width: 100%;
  max-width: 100%;
  height: 100%;
  object-fit: contain;
  transition: all 0.4s;
}
.left > div > .text h2 {
  font-size: 16px;
  color: #3c3c3c;
}

.left > div > .text .cnt {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 14px;
  color: #999999;
}
.left > div > .text .time {
  font-size: 14px;
  color: #999999;
  margin: 10px 0 15px;
}
.more {
  width: 100%;
  height: 40px;
  line-height: 40px;
  justify-content: center;
  align-items: center;
  background-color: #e3e2e2;
  font-size: 14px;
  color: #676767;
  transition: all 0.4s;
}
.more:hover {
  background-color: #dad8d8;
  border-radius: 4px;
}
.more img {
  margin-left: 10px;
  transition: all 0.4s;
}
.more:hover img {
  transform: translateX(10px);
}
</style>
<style>
.left > div > .text .cnt img {
  display: none;
}
</style>
