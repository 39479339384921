<template>
  <div class="right">
    <ul>
      <li
        :class="$store.state.currentPage == index ? 'dot-wp' : ''"
        v-for="(item, index) in list"
        :key="index"
        @click="dotClick(index)"
      >
        <img src="@/assets/img/home.png" alt="" width="15" v-if="index == 0" />
        <span class="dot" v-if="index != 0"></span>
        <span class="label">{{ item.name }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      list: [
        {
          name: "微智能数字孪生",
        },
        {
          name: "场景介绍",
        },
        {
          name: "公司模块",
        },
        {
          name: "新闻中心",
        },
      ],
    };
  },
  methods: {
    dotClick(index) {
      this.$store.state.currentPage = index;
      // if (index < 2) {
      //   window.scrollTo({
      //     top: window.innerHeight * 1.5 * index,
      //     behavior: "smooth",
      //   });
      // } else {
      //   window.scrollTo({
      //     top: window.innerHeight * (index - 1) + window.innerHeight * 1.5,
      //     behavior: "smooth",
      //   });
      // }
      if (index == 0) {
        this.$store.state.bar.scrollTo({
          top: this.$store.state.page1.offsetTop,
          behavior: "smooth",
        });
      } else if (index == 1) {
        console.log(this.$store.state.page2);
        this.$store.state.bar.scrollTo({
          top: this.$store.state.page2.offsetTop,
          behavior: "smooth",
        });
      } else if (index == 2) {
        this.$store.state.bar.scrollTo({
          top: this.$store.state.page3.offsetTop,
          behavior: "smooth",
        });
      } else if (index == 3) {
        this.$store.state.bar.scrollTo({
          top: this.$store.state.page4.offsetTop,
          behavior: "smooth",
        });
      }
    },
  },
};
</script>

<style scoped>
.right {
  position: fixed;
  right: 30px;
  top: 50%;
  z-index: 100;
  transform: translateY(-50%);
}
ul {
  background: #fff;
  padding: 10px 5px;
  border-radius: 100px;
}
li {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin: 8px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid rgba(0, 0, 0, 0);
  transition: 0.3s;
  position: relative;
  cursor: pointer;
}
ul > li:hover {
  border: 2px solid #01aeeb;
}
ul > li:hover > .label {
  visibility: visible;
  opacity: 1;
  transform: translate(0);
}
.dot-wp {
  border: 2px solid #01aeeb;
}
.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #01aeeb;
}
.label {
  position: absolute;
  right: 35px;
  display: ruby;
  visibility: hidden;
  opacity: 0;
  transform: translate(-5px);
  transition: 0.5s;
  color: #01aeeb;
}
</style>
