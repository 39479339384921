import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import camera from "./camera";
import renderer from "./renderer";
const controls = new OrbitControls(camera, renderer.domElement);
// 设置轨道自然
controls.enableDamping = true;
// controls.target+=200
// controls.autoRotate = true; //旋转
// 当.autoRotate为true时，围绕目标旋转的速度将有多快，默认值为2.0，相当于在60fps时每旋转一周需要30秒。
controls.autoRotateSpeed = 1;
// 当使用键盘按键的时候，相机平移的速度有多快。默认值为每次按下按键时平移7像素。
controls.keyPanSpeed = 7;
// controls.minPolarAngle = (Math.PI / 4) * 3; //最小垂直角度
// controls.maxPolarAngle = Math.PI / 2; //最大垂直角度
// 设置惯性
// controls.update()

controls.minDistance = 0;
controls.maxDistance = 1800;
export default controls;
